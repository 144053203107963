import React, { useState } from 'react';

const steps = [
    "Case",
    "Liability",
    "Medical Providers",
    "Pain & Suffering",
    "Economic Damages",
];

const Progressbar = ({ formSteps, fromStepValue, onNextClick }) => {

    const renderIcon = (index) => {
        if (index < formSteps) {
            // Completed Step Icon
            return (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L16.073 6.929L14.659 5.515L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z"
                        fill="#174F86"
                    />
                </svg>
            );
        } else if (index === formSteps) {
            // Current Step Icon
            return (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10" cy="10" r="7" fill="#174F86" />
                    <circle cx="10" cy="10" r="9" stroke="#174F86" strokeWidth="2" />
                </svg>
            );
        } else {
            // Inactive Step Icon
            return (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="10"
                        cy="10"
                        r="9"
                        fill="#E9F1F9"
                        stroke="#CFD8E7"
                        strokeWidth="2"
                    />
                </svg>
            );
        }
    };

    return (
        <div>
            <h1 className="section-title mb-0">Create Case</h1>
            <div className="stepper-progress d-flex gap-5 align-items-center">
                <div className="stepper d-flex align-items-center">
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`step ${index <= formSteps ? "active" : ""
                                    } d-flex align-items-center`}
                            >
                                {renderIcon(index)}
                                <span style={{ cursor: 'pointer' }} onClick={() => fromStepValue(index)}>{step}</span>
                            </div>
                            {/* Render a line only between steps */}
                            {index < steps.length - 1 && <div className="line"></div>}
                        </React.Fragment>
                    ))}
                </div>
                <button type='button' onClick={onNextClick} className="btn btn-theme mt-4">{formSteps === 4 ? 'Submit' : 'Next'}</button>
            </div>
        </div>
    );
};
export default Progressbar;