import React, { useState, useEffect, useRef, useCallback } from "react";
import '../style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import HorizontalGraph from "./components/HorizontalGraph";
import VerticalGraph from "./components/VerticalGraph";
import useAxios from "../../../hooks/useAxios";
import Constants from "../../../Constants";
import { toaster } from "../../../utils/toast";
import XLoader from "../../../components/common/XLoader";
import { useSelector } from "react-redux";
import { parseAndFormatUtcDate } from "../../../Utils";
import DateRangePicker from "./components/DateRangePicker";


const Dashboard = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const [userAnalyticDataPoint, setUserAnalyticDataPoint] = useState([]);
    const [monthlyAnalyticDataPoint, setMonthlyAnalyticDataPoint] = useState([]);
    const [from, setFrom] = useState(startOfMonth);
    const [to, setTo] = useState(currentDate);
    const fromDatePickerRef = useRef(null);
    const toDatePickerRef = useRef(null);
    const [averageTimeTaken, setAverageTimeTaken] = useState("");
    const [totalNumCases, setTotalNumCases] = useState("");
    const [cardDetailData, setCardDetailData] = useState(null);
    const { subscriptionRenewalDate } = useSelector((state) => state.users?.subscription || {});
    const [isActive, setIsActive] = useState(false);
    const [isActiveAccrued, setIsActiveAccrued] = useState(false);
    const [isActiveCarte, setIsActiveCarte] = useState(false);
    const [selectedRange, setSelectedRange] = useState({});

    const { postData: userAnalyticsPostData, isLoading: userAnalyticsIsLoading } = useAxios()
    const { postData: monthlyPostData, isLoading: monthlyAnalyticsIsLoading } = useAxios()


    const getUserAnalytics = useCallback(async () => {
        const { success, data, message } = await userAnalyticsPostData(Constants.ApiUrl.company.dashboard.userAnalytics, { selectedRange: selectedRange });
        if (success) {
            const { topUserAnalytics, caseOverview } = data;
            setUserAnalyticDataPoint(topUserAnalytics);
            setAverageTimeTaken(caseOverview?.averageTimeTaken);
            setTotalNumCases(caseOverview?.totalCases);
        } else {
            toaster({ message, success });
        }
    }, [selectedRange, userAnalyticsPostData]);

    const getMonthlyAnalytics = useCallback(async () => {
        const { success, data, message } = await monthlyPostData(Constants.ApiUrl.company.dashboard.monthlyAnalytics, {});

        if (success) {
            const { last12MonthData, cardDetail } = data;
            console.log(cardDetail)
            setMonthlyAnalyticDataPoint(last12MonthData);
            setCardDetailData(cardDetail)
        } else {
            toaster({ message, success });
        }
    }, [monthlyPostData]);

    useEffect(() => {
        if (from && to) {
            getUserAnalytics()
        }
    }, [selectedRange, getUserAnalytics])

    useEffect(() => {
        getMonthlyAnalytics()
    }, [getMonthlyAnalytics])

    const [isOpenUserGraph, setOpenGraphData] = useState(true);
    const [isOpenDateRange, setIsOpenDateRange] = useState(false);

    const handleViewAll = () => {
        setIsOpenDateRange(false);
        setSelectedRange({});
    }

    return (
        <div className="listing">
            <div className="graphs-wrap">
                <div className="d-flex justify-content-between align-items-center mb-6">
                    <h1 className="section-title">Dashboard</h1>
                    <p className="mb-0 text-sm text-default-color">Your subscription renews January 4, 2024</p>
                </div>
                <div className="row graph-container db-container">
                    <div className="col-md-7">
                        <div className="flex">
                            <div className="label me-3 f-x-large">Demands generated by</div>
                            {/* {/ <div className="label me-3 f-x-large"> {parseAndFormatUtcDate(subscriptionRenewalDate)}</div > /} */}
                            <div className="btn-group graph-tabs" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" onChange={() => setOpenGraphData(!isOpenUserGraph)} className="btn-check" name="graphData" id="btnradio1" autocomplete="off" checked={isOpenUserGraph} />
                                <label className="btn btn-outline-primary" for="btnradio1">User</label>

                                <input type="radio" onChange={() => setOpenGraphData(!isOpenUserGraph)} className="btn-check" name="graphData" id="btnradio2" autocomplete="off" checked={!isOpenUserGraph} />
                                <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                            </div>
                        </div>
                        <div className="graph-container-one d-flex">
                            {(userAnalyticsIsLoading && !isOpenUserGraph) && <XLoader />}
                            {isOpenUserGraph ? <HorizontalGraph dataPoints={userAnalyticDataPoint} totalNumCases={totalNumCases} graph={true} /> : <VerticalGraph dataPoints={monthlyAnalyticDataPoint} totalNumCases={totalNumCases} graph={true} />}
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <p className="mb-0 text-sm text-default-color">Showing top 10 this month</p>
                                <div className="graph-actions d-flex align-items-center">
                                    {isOpenDateRange && <a><DateRangePicker onRangeChange={(ranges) => {
                                        setSelectedRange({ ...ranges })
                                    }} /></a>}
                                    {!isOpenDateRange && <a onClick={() => setIsOpenDateRange(!isOpenDateRange)} className="graph-link">Change date range</a>}
                                    <a onClick={() => handleViewAll()} className="graph-link">View All</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 page-container">
                        <div className="top-content">Average Demand Creation Time:</div>
                        <div className="center-content"><span>{averageTimeTaken}</span> Minutes</div>
                        <div className="bottom-right-text">{totalNumCases} demands</div>
                    </div>
                </div>
            </div>
            <div className="stats-cards db-cards mb-0">
                <div className="row">
                    <div className="col-md-3">
                        <div className={isActive ? "item flipped" : "item"}>
                            <div className="inner-content" onClick={() => setIsActive(!isActive)}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#2277C9" />
                                        <path d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z" fill="#2277C9" />
                                    </svg>
                                </span>
                                <p className="title">Credit Balance</p>
                                <span className="count"><span>{cardDetailData?.monthlyRemainingDemand}</span> / {cardDetailData?.demandsPerMonth}</span>
                            </div>

                            <div className="back-content" onClick={() => setIsActive(!isActive)}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#2277C9" />
                                        <path d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z" fill="#2277C9" />
                                    </svg>
                                </span>
                                <p className="title">Credit Balance</p>
                                <p className="description">
                                    Your current plan is {cardDetailData?.demandsPerMonth} credits per month. You have {cardDetailData?.monthlyRemainingDemand} credits remaining.
                                </p>
                                <a className="link">Upgrade your plan</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className={isActiveAccrued ? "item flipped" : "item"}>
                            <div className="inner-content" onClick={() => setIsActiveAccrued(!isActiveAccrued)}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#2277C9" />
                                        <path d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z" fill="#2277C9" />
                                    </svg>
                                </span>
                                <p className="title">Accrued</p>
                                <span className="count"><span>{cardDetailData?.rollOverCredits}</span> </span>
                            </div>

                            <div className="back-content" onClick={() => setIsActiveAccrued(!isActiveAccrued)}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#2277C9" />
                                        <path d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z" fill="#2277C9" />
                                    </svg>
                                </span>
                                <p className="title">Accrued</p>
                                <p className="description">
                                    Accrued credits will be used first before applying next month's credits.                                </p>
                                <a className="link">Manage your plan</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className={isActiveCarte ? "item flipped" : "item"}>
                            <div className="inner-content" onClick={() => setIsActiveCarte(!isActiveCarte)}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#2277C9" />
                                        <path d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z" fill="#2277C9" />
                                    </svg>
                                </span>
                                <p className="title">A La Carte</p>
                                <span className="count"><span>{cardDetailData?.monthlyAlaCarteDemand}</span> this month</span>
                            </div>

                            <div className="back-content" onClick={() => setIsActiveCarte(!isActiveCarte)}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#2277C9" />
                                        <path d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z" fill="#2277C9" />
                                    </svg>
                                </span>
                                <p className="title">A La Carte</p>
                                <p className="description">
                                    Accrued credits will be used first before applying next month's credits.
                                </p>
                                <a className="link">Manage your plan</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="item">
                            <p className="title">Users</p>
                            <span className="count"><span>{cardDetailData?.totalUsers}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;