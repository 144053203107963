import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import '../../style.css';
import 'react-datepicker/dist/react-datepicker.css';

const data = [
    { name: "David Jones", value: 7 },
    { name: "Lauren Vega", value: 6 },
    { name: "Bobby Marks", value: 6 },
    { name: "Travis Easton", value: 5 },
    { name: "Stanley Polka", value: 5 },
    { name: "Janice Lee", value: 5 },
    { name: "Toby Jacobs", value: 4 },
    { name: "Jonathan Filbert", value: 3 },
    { name: "Rachel Stone", value: 1 },
    { name: "John Stack", value: 0 }
];


const dataV = [
    { name: "Sept", value: 5 },
    { name: "Oct", value: 6 },
    { name: "Nov", value: 6 },
    { name: "Dec", value: 5 },
    { name: "Feb", value: 5 },
    { name: "March", value: 5 },
    { name: "April", value: 4 },
    { name: "May", value: 3 },
    { name: "June", value: 1 },
    { name: "July", value: 0 },
    { name: "Aug", value: 8 }
];

const HorizontalGraph = ({ dataPoints, totalNumCases, graph, MPoints, yAxisLabel = 'Users' }) => {

    const chartRef = useRef();

    useEffect(() => {
        drawChart();
        console.log(dataPoints, MPoints, "Mdata");
    }, [MPoints, dataPoints]);

    const drawChart = () => {

        if (!dataPoints || dataPoints.length === 0) return;
        const width = 500;
        const height = dataPoints.length * 50;
        const margin = { top: 100, right: 50, bottom: 30, left: 100 };

        d3.select(chartRef.current).selectAll("*").remove(); // Clear previous SVG content

        const svg = d3.select(chartRef.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const xScale = d3.scaleLinear()
            .domain([0, d3.max(dataPoints, d => d.y) || 1])
            .range([0, width]);
        const yScale = d3.scaleBand()
            .domain(dataPoints.map(d => d.label))
            .range([0, height])
            .padding(0.6); // Increased padding for more gap

        // Define gradient
        const defs = svg.append("defs");
        const gradient = defs.append("linearGradient")
            .attr("id", "barGradient")
            .attr("x1", "0%")  // Start from left
            .attr("x2", "100%") // End at right
            .attr("y1", "0%")  // Slight diagonal effect
            .attr("y2", "100%");

        // Define color stops based on your UI
        gradient.append("stop").attr("offset", "2%").attr("stop-color", "#06F8E3");  // Cyan start
        gradient.append("stop").attr("offset", "34%").attr("stop-color", "#2C93E8"); // Mid soft blue
        gradient.append("stop").attr("offset", "100%").attr("stop-color", "#0A3381"); // Deep blue end

        // Draw bars with only the right side rounded
        svg.selectAll(".bar")
            .data(dataPoints)
            .enter()
            .append("path")
            .attr("d", d => {
                const barWidth = xScale(d.y);
                const barHeight = yScale.bandwidth() * 0.7; // Reduced height
                const radius = 5; // Rounded corner radius

                return `
                        M 0,0 
                        H ${barWidth - radius} 
                        A ${radius},${radius} 0 0 1 ${barWidth},${radius} 
                        V ${barHeight - radius} 
                        A ${radius},${radius} 0 0 1 ${barWidth - radius},${barHeight} 
                        H 0 
                        Z
                    `;
            })
            .attr("transform", d => `translate(0,${yScale(d.label)})`)
            .attr("fill", "url(#barGradient)");

        // Add labels on bars
        svg.selectAll(".text")
            .data(dataPoints)
            .enter()
            .append("text")
            .attr("x", d => xScale(d.y) + 5)
            .attr("y", d => yScale(d.label) + yScale.bandwidth() / 1.5) // Adjusted label position
            .attr("fill", "#333")
            .attr("font-size", "14px")
            .attr("font-weight", "normal")
            .text(d => d.y);

        // Add Y Axis Labels (remove axis lines)
        const yAxis = svg.append("g")
            .call(d3.axisLeft(yScale).tickSize(0).tickPadding(5));
        yAxis.select(".domain").remove(); // Remove the Y-axis line

        // Add X Axis Labels (remove axis lines)
        const xAxis = svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale).ticks(7).tickSize(7)); // No tick lines
        xAxis.select(".domain").remove(); // Remove the X-axis line

    };

    return (
        <>
            <div className="graph-container-one" style={{ paddingTop: '20px', textAlign: "center", paddingBottom: '20px' }}>
                <svg ref={chartRef} width="1000" height="400"></svg>
            </div>
        </>
    );
};

export default HorizontalGraph;