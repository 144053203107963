import React, { useState, useEffect } from 'react';
import { Field, Formik, Form } from 'formik';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TextInput, RenderIf, Dialog } from '../../../../components';
import { postMedicalRecords, preMedicalRecords } from '../../../../utils/helper';
import { storeMedicalProvider } from '../../../../redux/actions/medicalProviders';
import { useSelector, useDispatch } from 'react-redux';
import { cancelCaseSubmission, formikRefCapture, isCaseSaved, preProcessedMedicalRecordsData } from '../../../../redux/actions/liabilty';
import { useParams } from 'react-router-dom';
import { confirm } from '../../../../utils/swal';
import { RedesignedFileUpload } from '../../../../components';
import FileUploadErrorModal from '../../../../utils/fileUploadErrorModal';

const SortablePostAccidentProvider = ({ id, providerName, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });


    return (
        <div ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition: isDragging ? "none" : "transform 200ms ease", // Removes flickering
                background: isDragging ? "#d1e0fc" : "#edf3fc", // Highlight dragged item
                padding: "15px",
                marginBottom: "10px",
                borderRadius: "5px",
                opacity: isDragging ? 0.6 : 1 // Reduce opacity while dragging
            }}
            {...attributes}
            {...listeners} className='report-section mb-4'>
            <div class="d-flex gap-4">
                <span class="move-icon">
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" fill="#828282" />
                        <circle cx="7" cy="2" r="2" fill="#828282" />
                        <circle cx="2" cy="7" r="2" fill="#828282" />
                        <circle cx="7" cy="7" r="2" fill="#828282" />
                        <circle cx="2" cy="12" r="2" fill="#828282" />
                        <circle cx="7" cy="12" r="2" fill="#828282" />
                    </svg>
                </span>
                <div class="w-100">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="report-btn w-100">
                            <div class="d-flex align-items-center gap-2">
                                <span class="card-title">{providerName}</span>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

const SortablePostAccidentFile = ({ id, entry }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'grab',
    };

    return (

        <div ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners} class="report-item">
            <div class="d-flex align-items-center">
                {/* move icon */}
                <span class="me-2"><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" fill="#828282" />
                    <circle cx="7" cy="2" r="2" fill="#828282" />
                    <circle cx="2" cy="7" r="2" fill="#828282" />
                    <circle cx="7" cy="7" r="2" fill="#828282" />
                    <circle cx="2" cy="12" r="2" fill="#828282" />
                    <circle cx="7" cy="12" r="2" fill="#828282" />
                </svg>
                </span>
                {/* move icon */}
                <svg class="me-2" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.95 16L12.6 10.35L11.15 8.9L6.925 13.125L4.825 11.025L3.4 12.45L6.95 16ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9Z" fill="#292B2E" />
                </svg>
                {entry}
            </div>
            {/* arrow icons */}
            <div>
                <span>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 11L11 15M11 15L15 11M11 15V7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#292B2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
                <span class="ms-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12L12 8M12 8L8 12M12 8L12 16M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" stroke="#292B2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
        </div>

    );
};

const SortablePreAccidentProvider = ({ id, providerName, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners} className='report-section mb-4'>
            <div class="d-flex gap-4">
                <span class="move-icon">
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" fill="#828282" />
                        <circle cx="7" cy="2" r="2" fill="#828282" />
                        <circle cx="2" cy="7" r="2" fill="#828282" />
                        <circle cx="7" cy="7" r="2" fill="#828282" />
                        <circle cx="2" cy="12" r="2" fill="#828282" />
                        <circle cx="7" cy="12" r="2" fill="#828282" />
                    </svg>
                </span>
                <div class="w-100">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="report-btn w-100">
                            <div class="d-flex align-items-center gap-2">
                                <span class="card-title">{providerName}</span>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

const SortablePreAccidentFile = ({ id, entry }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'grab',
    };

    return (

        <div ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners} class="report-item">
            <div class="d-flex align-items-center">
                {/* move icon */}
                <span class="me-2"><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" fill="#828282" />
                    <circle cx="7" cy="2" r="2" fill="#828282" />
                    <circle cx="2" cy="7" r="2" fill="#828282" />
                    <circle cx="7" cy="7" r="2" fill="#828282" />
                    <circle cx="2" cy="12" r="2" fill="#828282" />
                    <circle cx="7" cy="12" r="2" fill="#828282" />
                </svg>
                </span>
                {/* move icon */}
                <svg class="me-2" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.95 16L12.6 10.35L11.15 8.9L6.925 13.125L4.825 11.025L3.4 12.45L6.95 16ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9Z" fill="#292B2E" />
                </svg>
                {entry}
            </div>
            {/* arrow icons */}
            <div>
                <span>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 11L11 15M11 15L15 11M11 15V7M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#292B2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
                <span class="ms-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12L12 8M12 8L8 12M12 8L12 16M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" stroke="#292B2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
        </div>

    );
};


const AddMedicalProviderBillForm = ({ onSubmit, stepDecrement, backToCases, innerRef }) => {

    const [isOpenProcessedMedicalRecordsModal, setIsOpenProcessedMedicalRecordsModal] = useState(false);
    const { caseId } = useParams();
    const dispatch = useDispatch();
    const medicalProviderData = useSelector(state => state.medicalProviderData.medicalProvider);
    const proccessedMedicalRecordsStored = useSelector(state => state.preProcessMedicalRecords.injuryData);

    useEffect(() => {
        dispatch(formikRefCapture(innerRef))
        console.log('Damages', innerRef)
    }, [])


    useEffect(() => {
        if (medicalProviderData?.postAccident || medicalProviderData?.preAccident) {
            setPostMedicalFiles(medicalProviderData?.postAccident)
            setPreMedicalFiles(medicalProviderData?.preAccident)
        }

    }, [medicalProviderData]);

    const [postMedicalFiles, setPostMedicalFiles] = useState([]);
    const [preMedicalFiles, setPreMedicalFiles] = useState([]);
    const [openPostAccidentReorder, setOpenPostAccidentReorder] = useState(false);
    const [openPreAccidentReorder, setOpenPreAccidentReorder] = useState(false);
    const [fileErrorList, setFileErrorList] = useState([]);

    const addPostMedicalFiles = ({ e, setFieldValue, values }) => {
        let files = Array.from(e.currentTarget.files);
        const validFiles = validationFilesFunc(files);
        const newEntry = {
            providerName: values.postAccident.providerName,
            medicalRecords: validFiles,
        };
        setPostMedicalFiles((prevPostMedicalFiles) => [...prevPostMedicalFiles, newEntry]);
        // Clear file input and provider name for new entry
        setFieldValue('postAccident.medicalRecords', []);
        setFieldValue('postAccident.providerName', '');
        setFieldValue('postMedicalFiles', [...values.postMedicalFiles, newEntry]);
    }

    const addPreMedicalFiles = ({ e, setFieldValue, values }) => {
        const files = Array.from(e.currentTarget.files);
        const validFiles = validationFilesFunc(files);
        const newEntry = {
            providerName: values.preAccident.providerName,
            medicalRecords: validFiles,
        };
        setPreMedicalFiles((prevPreMedicalFiles = []) => [...prevPreMedicalFiles, newEntry]);
        // Clear file input and provider name for new entry
        setFieldValue('preAccident.medicalRecords', []);
        setFieldValue('preAccident.providerName', '');
        setFieldValue('preMedicalFiles', [...values.preMedicalFiles, newEntry]);

    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handlePostDragEnd = (event, postMedicalFiles, setFieldValue) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;
        const oldIndex = postMedicalFiles.findIndex(item => item.providerName === active.id?.providerName);
        const newIndex = postMedicalFiles.findIndex(item => item.providerName === over.id?.providerName);
        if (oldIndex === -1 || newIndex === -1) return;
        const updatedFiles = arrayMove(postMedicalFiles, oldIndex, newIndex);
        setFieldValue('postMedicalFiles', updatedFiles);
    };

    const handlePostFileDragEnd = (providerId, postMedicalFiles, setFieldValue) => (event) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;
        const updatedProviders = postMedicalFiles.map((provider) => {
            if (provider.providerName === providerId) {
                const oldIndex = provider.medicalRecords.findIndex(file => file.name === active.id);
                const newIndex = provider.medicalRecords.findIndex(file => file.name === over.id);
                if (oldIndex === -1 || newIndex === -1) return provider;
                return {
                    ...provider,
                    medicalRecords: arrayMove(provider.medicalRecords, oldIndex, newIndex),
                };
            }
            return provider;
        });
        setFieldValue('postMedicalFiles', updatedProviders);
    };

    const handlePreDragEnd = (event, preMedicalFiles, setFieldValue) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;
        const oldIndex = preMedicalFiles.findIndex(item => item.providerName === active.id?.providerName);
        const newIndex = preMedicalFiles.findIndex(item => item.providerName === over.id?.providerName);
        if (oldIndex === -1 || newIndex === -1) return;
        const updatedFiles = arrayMove(preMedicalFiles, oldIndex, newIndex);
        setFieldValue('preMedicalFiles', updatedFiles);
    };

    const handlePreFileDragEnd = (providerId, preMedicalFiles, setFieldValue) => (event) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;
        const updatedProviders = preMedicalFiles.map((provider) => {
            if (provider.providerName === providerId) {
                const oldIndex = provider.medicalRecords.findIndex(file => file.name === active.id);
                const newIndex = provider.medicalRecords.findIndex(file => file.name === over.id);
                if (oldIndex === -1 || newIndex === -1) return provider;
                return {
                    ...provider,
                    medicalRecords: arrayMove(provider.medicalRecords, oldIndex, newIndex),
                };
            }
            return provider;
        });
        setFieldValue('preMedicalFiles', updatedProviders);
    };

    const removePostProvider = (indexToDelete, setFieldValue, values) => {
        const updatedFiles = values.postMedicalFiles.filter((_, idx) => idx !== indexToDelete);

        setFieldValue("postMedicalFiles", updatedFiles);
    };

    const removePreProvider = (indexToDelete, setFieldValue, values) => {
        const updatedFiles = values.preMedicalFiles.filter((_, idx) => idx !== indexToDelete);

        setFieldValue("preMedicalFiles", updatedFiles);
    };

    const removePostMedicalFile = (providerIndex, fileIndex, setFieldValue, values) => {
        const updatedFiles = values.postMedicalFiles[providerIndex].medicalRecords.filter(
            (_, idx) => idx !== fileIndex
        );
        setFieldValue(`postMedicalFiles.${providerIndex}.medicalRecords`, updatedFiles);
    };

    const removePreMedicalFile = (providerIndex, fileIndex, setFieldValue, values) => {
        const updatedFiles = values.preMedicalFiles[providerIndex].medicalRecords.filter(
            (_, idx) => idx !== fileIndex
        );
        setFieldValue(`preMedicalFiles.${providerIndex}.medicalRecords`, updatedFiles);
    };

    const [isPostEditProvider, setPostEditProvider] = useState(
        Array(postMedicalFiles?.length).fill(false) // Ensures an array of "false"
    );

    const [isPreEditProvider, setPreEditProvider] = useState(
        Array(preMedicalFiles?.length).fill(false) // Ensures an array of "false"
    );

    const togglePostEdit = (index) => {
        setPostEditProvider((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index]; // Toggle specific index
            return updatedState;
        });
    };

    const togglePreEdit = (index) => {
        setPreEditProvider((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index]; // Toggle specific index
            return updatedState;
        });
    };

    const validationFilesFunc = (files) => {
        const errorList = [];
        files = files.filter((item) => {
            if (item.size === 0) {
                errorList.push({
                    fileName: item?.name,
                    fileSize: item.size
                });
                return false
            } else if (item.size > (500 * 1024 * 1024)) {
                errorList.push({
                    fileName: item?.name,
                    fileSize: item.size,
                    isFileLarge: true
                });
                return false
            }
            else if (item.type !== "application/pdf") {
                errorList.push({
                    fileName: item?.name,
                    fileTypeError: true
                });
                return false
            }
            return true;
        });
        if (errorList.length) {
            setFileErrorList(errorList);
        }
        return files;
    }

    const onPostFileSelected = async (files, providerIndex, setFieldValue, values) => {
        let newFiles = Array.from(files.file);
        const validFiles = validationFilesFunc(newFiles);
        const existingRecords = values.postMedicalFiles[providerIndex]?.medicalRecords || [];
        const combinedArr = [...existingRecords, ...validFiles];
        const updatedRecords = [...new Map(combinedArr.map(item => [item.name, item])).values()];
        setFieldValue(`postMedicalFiles.${providerIndex}.medicalRecords`, updatedRecords);
    };

    const onPreFileSelected = async (files, providerIndex, setFieldValue, values) => {
        const newFiles = Array.from(files.file);
        const validFiles = validationFilesFunc(newFiles);
        const existingRecords = values.preMedicalFiles[providerIndex]?.medicalRecords || [];
        const combinedArr = [...existingRecords, ...validFiles];
        const updatedRecords = [...new Map(combinedArr.map(item => [item.name, item])).values()];
        setFieldValue(`preMedicalFiles.${providerIndex}.medicalRecords`, updatedRecords);
    };

    const onFormikSubmitHandler = (values, isDraftCase = false) => {
        const medicalRecords = {
            postAccident: values.postMedicalFiles,
            preAccident: values.preMedicalFiles,
        }

        if (isDraftCase) {
            const postMedicalRecord = postMedicalRecords(medicalRecords, caseId);
            const preMedicalRecord = preMedicalRecords(medicalRecords, caseId);
            if (postMedicalRecord?.length || preMedicalRecord?.length) {
                dispatch(preProcessedMedicalRecordsData({
                    ...proccessedMedicalRecordsStored,
                    isPreProcessRecordLoading: true,
                }));

            } else {
                confirm({ icon: "error", title: "Please Upload files" });
                return false;
            }
        }

        dispatch(storeMedicalProvider(medicalRecords))
        onSubmit({
            medicalProviders: medicalRecords,
            isDraftCase
        })

    }

    const viewMedicalRecords = (e, value) => {
        e.preventDefault()
        setIsOpenProcessedMedicalRecordsModal(value)
    }

    const onToggleMedicalRecordsCaseDialog = (isDialogOpen) => {
        setIsOpenProcessedMedicalRecordsModal(isDialogOpen)
    }

    const isMedicalRecordsGeneratedPreviously = proccessedMedicalRecordsStored?.medicalProcessedData?.length || proccessedMedicalRecordsStored?.preMedicalProcessedData?.length || proccessedMedicalRecordsStored?.preMedicalBillProccessedData?.length


    return (
        <>
            <Formik
                initialValues={{
                    postMedicalFiles: medicalProviderData?.postAccident || [],
                    preMedicalFiles: medicalProviderData?.preAccident || [],
                    postAccident: { providerName: "", medicalRecords: [] },
                    preAccident: { providerName: "", medicalRecords: [] }
                }}
                enableReinitialize={true}
                onSubmit={(values) => {
                    dispatch(cancelCaseSubmission(true))
                    dispatch(isCaseSaved(false))

                    onFormikSubmitHandler(values)
                }}
            >
                {({ setFieldValue, values }) => (
                    <Form>

                        {values.postMedicalFiles?.length > 0 && openPostAccidentReorder && <div>
                            <div className='d-flex justify-content-between align-items-center btm-btns mb-4'>
                                <h3>Reorder Post-Accident Related Medical Providers</h3>
                                <a className="text-theme"></a>
                                <div className="btns text-center button-spacing">
                                    <button className="btn btn-theme btn-border" type="button" onClick={() => setOpenPostAccidentReorder(false)}>Cancel</button>
                                    <button className="btn btn-theme" type="button" onClick={() => setOpenPostAccidentReorder(false)}>Save</button>
                                </div>
                            </div>

                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={(e) => handlePostDragEnd(e, values.postMedicalFiles, setFieldValue)}
                            >
                                <SortableContext
                                    items={values.postMedicalFiles}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {values.postMedicalFiles?.map((provider, index) => (
                                        <SortablePostAccidentProvider key={index} id={provider} providerName={provider.providerName}>
                                            <DndContext
                                                sensors={sensors}
                                                collisionDetection={closestCenter}
                                                onDragEnd={handlePostFileDragEnd(provider.providerName, values.postMedicalFiles, setFieldValue)}
                                            >
                                                <SortableContext
                                                    items={provider.medicalRecords.map(file => file.name)}
                                                    strategy={verticalListSortingStrategy}
                                                >
                                                    {provider?.medicalRecords.map((file, index) => (
                                                        <SortablePostAccidentFile key={file.name} id={file.name} entry={file.name} />
                                                    ))}
                                                </SortableContext>
                                            </DndContext>
                                        </SortablePostAccidentProvider>
                                    ))}
                                </SortableContext>
                            </DndContext>
                            <div className='d-flex justify-content-between align-items-center btm-btns'>
                                <a className="text-theme"></a>
                                <div className="btns text-center button-spacing">
                                    <button className="btn btn-theme btn-border" type="button" onClick={() => setOpenPostAccidentReorder(false)}>Cancel</button>
                                    <button className="btn btn-theme" type="button" onClick={() => setOpenPostAccidentReorder(false)}>Save</button>
                                </div>
                            </div>
                        </div>}
                        {values.preMedicalFiles?.length > 0 && openPreAccidentReorder && <div>
                            <div className='d-flex justify-content-between align-items-center btm-btns mb-4'>
                                <h3>Reorder Post-Accident Related Medical Providers</h3>
                                <a className="text-theme"></a>
                                <div className="btns text-center button-spacing">
                                    <button className="btn btn-theme btn-border" type="submit" onClick={() => setOpenPreAccidentReorder(false)}>Cancel</button>
                                    <button className="btn btn-theme" type="submit" onClick={() => setOpenPreAccidentReorder(false)}>Save</button>
                                </div>
                            </div>

                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={(e) => handlePreDragEnd(e, values.preMedicalFiles, setFieldValue)}
                            >
                                <SortableContext
                                    items={values.preMedicalFiles}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {values.preMedicalFiles?.map((provider, index) => (
                                        <SortablePreAccidentProvider key={index} id={provider} providerName={provider.providerName}>
                                            <DndContext
                                                sensors={sensors}
                                                collisionDetection={closestCenter}
                                                onDragEnd={handlePreFileDragEnd(provider.providerName, values.preMedicalFiles, setFieldValue)}
                                            >
                                                <SortableContext
                                                    items={provider.medicalRecords.map(file => file.name)}
                                                    strategy={verticalListSortingStrategy}
                                                >
                                                    {provider?.medicalRecords.map((file, index) => (
                                                        <SortablePreAccidentFile key={file.name} id={file.name} entry={file.name} />
                                                    ))}
                                                </SortableContext>
                                            </DndContext>
                                        </SortablePreAccidentProvider>
                                    ))}
                                </SortableContext>
                            </DndContext>
                            <div className='d-flex justify-content-between align-items-center btm-btns'>
                                <a className="text-theme"></a>
                                <div className="btns text-center button-spacing">
                                    <button className="btn btn-theme btn-border" type="button" onClick={() => setOpenPreAccidentReorder(false)}>Cancel</button>
                                    <button className="btn btn-theme" type="button" onClick={() => setOpenPreAccidentReorder(false)}>Save</button>
                                </div>
                            </div>
                        </div>}
                        {!openPostAccidentReorder && !openPreAccidentReorder && <div className="add-form p-0">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item pb-4">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div className="justify-content-between align-items-center w-100 me-3">
                                                <span>Post-Accident Medical Providers</span>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                                        <div class="accordion-body">
                                            {values.postMedicalFiles?.map((entry, index) => (
                                                <>
                                                    <div key={index} className='report-section  position-relative file-input mb-4'>
                                                        <div class="d-flex align-items-center gap-4">
                                                            <div class="w-100">
                                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                                    <div class="report-btn w-100">
                                                                        <div class="d-flex align-items-center gap-2">
                                                                            {!isPostEditProvider[index] ? (
                                                                                <>
                                                                                    <span className="card-title">{entry?.providerName}</span>
                                                                                    <svg
                                                                                        onClick={() => togglePostEdit(index)}
                                                                                        width="19"
                                                                                        height="19"
                                                                                        viewBox="0 0 19 19"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        className='show-item'
                                                                                    >
                                                                                        <path d="M2.17521 18C1.82521 18.0833 1.52105 17.9958 1.26271 17.7375C1.00438 17.4792 0.916881 17.175 1.00021 16.825L2.00021 12.05L6.95021 17L2.17521 18ZM6.95021 17L2.00021 12.05L13.4502 0.599994C13.8335 0.216661 14.3085 0.0249939 14.8752 0.0249939C15.4419 0.0249939 15.9169 0.216661 16.3002 0.599994L18.4002 2.69999C18.7835 3.08333 18.9752 3.55833 18.9752 4.12499C18.9752 4.69166 18.7835 5.16666 18.4002 5.54999L6.95021 17ZM14.8752 1.99999L4.52521 12.35L6.65021 14.475L17.0002 4.12499L14.8752 1.99999Z" fill="#292B2E" />
                                                                                    </svg>
                                                                                </>
                                                                            ) : (
                                                                                <div className='show-item d-flex gap-2'>
                                                                                    <Field name={`postMedicalFiles.${index}.providerName`}
                                                                                        onChange={(e) => setFieldValue(`postMedicalFiles.${index}.providerName`, e.target.value)}
                                                                                        component={TextInput} />
                                                                                    <button className="btn btn-theme show-item" type="submit" onClick={() => {
                                                                                        setPostEditProvider(prevState => {
                                                                                            const updatedState = [...prevState];
                                                                                            updatedState[index] = false;
                                                                                            return updatedState;
                                                                                        });
                                                                                    }}>Save</button>
                                                                                </div>)}
                                                                        </div>
                                                                        <svg className='show-item' style={{ cursor: 'pointer' }} onClick={() => removePostProvider(index, setFieldValue, values)} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#292B2E" />
                                                                        </svg>

                                                                    </div>
                                                                </div>
                                                                {entry.medicalRecords.map((file, fileIndex) => (
                                                                    <div key={fileIndex} class="report-item show-item">
                                                                        <div class="d-flex align-items-center">

                                                                            {/* move icon */}
                                                                            <svg class="me-2" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M6.95 16L12.6 10.35L11.15 8.9L6.925 13.125L4.825 11.025L3.4 12.45L6.95 16ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9Z" fill="#292B2E" />
                                                                            </svg>
                                                                            {file.name}
                                                                        </div>
                                                                        <button type='button' class="delete-btn" onClick={(e) => removePostMedicalFile(index, fileIndex, setFieldValue, values)}>
                                                                            <i class="fas fa-trash-alt"></i>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div class="mt-4 text-end">
                                                            <button class="btn btn-theme btn-white-blue me-2 show-item" type='button' onClick={() => setOpenPostAccidentReorder(true)}>Reorder Providers</button>
                                                            <button class="btn btn-theme btn-white-blue" type='button'>
                                                                <span class="d-flex align-items-center gap-2 text-theme file-input fw-bolder"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.666992 13.6666V12.3333H11.3337V13.6666H0.666992ZM4.00033 11V6.33331H1.33366L6.00033 0.333313L10.667 6.33331H8.00033V11H4.00033Z" fill="#2277C9" />
                                                                </svg>
                                                                    Upload Records / Bills
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <Field name="medicalBills" component={RedesignedFileUpload} multiplefileUpload={true} onFileSelected={(files) => onPostFileSelected(files, index, setFieldValue, values)} documentName='MedicalBills' accept="application/pdf" />
                                                    </div >

                                                </>
                                            ))}
                                            <div class="card-item file-input">
                                                <h4 className="card-title mb-4">Add Provider
                                                </h4>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div>
                                                            <div class="d-flex align-items-center gap-2">
                                                                <div class="flex-grow-1 show-item">
                                                                    <Field name={`postAccident.providerName`} label="Medical Provider / Facility" component={TextInput} />
                                                                </div>
                                                                <div class="m-t-3 text-md-end ">
                                                                    <label class="btn btn-theme btn-border bg-transparent file-input d-flex align-items-center gap-2">
                                                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M0.666992 13.6667V12.3334H11.3337V13.6667H0.666992ZM4.00033 11V6.33337H1.33366L6.00033 0.333374L10.667 6.33337H8.00033V11H4.00033Z" fill="#2277C9" />
                                                                        </svg>
                                                                        Upload Medical Records / Bills
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Field
                                                    type="file"
                                                    accept="application/pdf"
                                                    id={`postAccident.medicalRecords`}
                                                    name='postAccident.medicalRecords'
                                                    multiple
                                                    onChange={(e) => {
                                                        addPostMedicalFiles({ e, setFieldValue, values })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item pb-4">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            <div className="justify-content-between align-items-center w-100 me-3">
                                                <span>Prior Medical Records</span>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo">
                                        <div class="accordion-body">
                                            {values.preMedicalFiles?.map((entry, index) => (
                                                <>
                                                    <div key={index} className='report-section mb-4 position-relative file-input show-item'>
                                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                                            <div class="report-btn w-100">
                                                                <div class="d-flex align-items-center gap-2">
                                                                    {!isPreEditProvider[index] ? (
                                                                        <>
                                                                            <span className="card-title">{entry?.providerName}</span>
                                                                            <svg
                                                                                onClick={() => togglePreEdit(index)}
                                                                                width="19"
                                                                                height="19"
                                                                                viewBox="0 0 19 19"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg" className='show-item'
                                                                            >
                                                                                <path d="M2.17521 18C1.82521 18.0833 1.52105 17.9958 1.26271 17.7375C1.00438 17.4792 0.916881 17.175 1.00021 16.825L2.00021 12.05L6.95021 17L2.17521 18ZM6.95021 17L2.00021 12.05L13.4502 0.599994C13.8335 0.216661 14.3085 0.0249939 14.8752 0.0249939C15.4419 0.0249939 15.9169 0.216661 16.3002 0.599994L18.4002 2.69999C18.7835 3.08333 18.9752 3.55833 18.9752 4.12499C18.9752 4.69166 18.7835 5.16666 18.4002 5.54999L6.95021 17ZM14.8752 1.99999L4.52521 12.35L6.65021 14.475L17.0002 4.12499L14.8752 1.99999Z" fill="#292B2E" />
                                                                            </svg>
                                                                        </>
                                                                    ) : (
                                                                        <div className='show-item d-flex gap-2'>
                                                                            {/* <Field name={`postMedicalFiles.${index}.providerName`} component="input" /> */}
                                                                            <Field name={`preMedicalFiles.${index}.providerName`}
                                                                                onChange={(e) => setFieldValue(`preMedicalFiles.${index}.providerName`, e.target.value)}
                                                                                component={TextInput} />
                                                                            <button className="btn btn-theme" type="submit" onClick={() => {
                                                                                setPreEditProvider(prevState => {
                                                                                    const updatedState = [...prevState];
                                                                                    updatedState[index] = false;
                                                                                    return updatedState;
                                                                                });
                                                                            }}>Save</button>
                                                                        </div>)}
                                                                </div>
                                                                <svg style={{ cursor: 'pointer' }} onClick={() => removePreProvider(index, setFieldValue, values)} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#292B2E" />
                                                                </svg>

                                                            </div>
                                                        </div>
                                                        {entry.medicalRecords.map((file, fileIndex) => (
                                                            <div key={fileIndex} class="report-item show-item">
                                                                <div class="d-flex align-items-center">
                                                                    <svg class="me-2" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.95 16L12.6 10.35L11.15 8.9L6.925 13.125L4.825 11.025L3.4 12.45L6.95 16ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9Z" fill="#292B2E" />
                                                                    </svg>
                                                                    {file.name}
                                                                </div>
                                                                <button type='button' class="delete-btn" onClick={(e) => removePreMedicalFile(index, fileIndex, setFieldValue, values)}>
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <div class="mt-4 text-end">
                                                            <button class="btn btn-theme btn-white-blue me-2" type='button' onClick={() => setOpenPreAccidentReorder(true)}>Reorder Providers</button>
                                                            <button class="btn btn-theme btn-white-blue" type='button'>
                                                                <span class="d-flex align-items-center gap-2 text-theme file-input fw-bolder"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.666992 13.6666V12.3333H11.3337V13.6666H0.666992ZM4.00033 11V6.33331H1.33366L6.00033 0.333313L10.667 6.33331H8.00033V11H4.00033Z" fill="#2277C9" />
                                                                </svg>
                                                                    Upload Records / Bills
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <Field name="medicalBills" component={RedesignedFileUpload} multiplefileUpload={true} onFileSelected={(files) => onPreFileSelected(files, index, setFieldValue, values)} documentName='MedicalBills' accept="application/pdf" />
                                                    </div>

                                                </>
                                            ))}
                                            <div class="card-item file-input">
                                                <h4 className="card-title mb-4">Add Provider
                                                </h4>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div>
                                                            <div class="d-flex align-items-center gap-2">
                                                                <div class="flex-grow-1 show-item">
                                                                    <Field name="preAccident.providerName" label="Medical Provider / Facility" component={TextInput} />
                                                                </div>

                                                                <div class="m-t-3 text-md-end ">
                                                                    <label class="btn btn-theme btn-border bg-transparent file-input d-flex align-items-center gap-2">
                                                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M0.666992 13.6667V12.3334H11.3337V13.6667H0.666992ZM4.00033 11V6.33337H1.33366L6.00033 0.333374L10.667 6.33337H8.00033V11H4.00033Z" fill="#2277C9" />
                                                                        </svg>
                                                                        Upload Medical Records / Bills

                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <Field
                                                    type="file"
                                                    accept="application/pdf"
                                                    id='preAccident.medicalRecords'
                                                    name='preAccident.medicalRecords'
                                                    multiple
                                                    onChange={(e) => {
                                                        addPreMedicalFiles({ e, setFieldValue, values })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="summary-section mt-5">
                                    <div class="gen-text">
                                        <span class="fw-bold">Medical Summary</span>

                                        {proccessedMedicalRecordsStored?.isPreProcessRecordLoading && <div class="custom-bar mt-4">
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <small class="mt-1">Generating medical summary can take 5 to 15 minutes...</small>
                                        </div>}

                                        <div className='flex gap-2'>
                                            <button type='button' class="btn btn-theme btn-white"
                                                disabled={proccessedMedicalRecordsStored?.isPreProcessRecordLoading}
                                                onClick={(e) => onFormikSubmitHandler(values, true)}
                                            >
                                                <span class="me-2">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.00033 2.66663L7.66699 6.33329L11.3337 7.99996L7.66699 9.66663L6.00033 13.3333L4.33366 9.66663L0.666992 7.99996L4.33366 6.33329L6.00033 2.66663ZM6.00033 5.88663L5.33366 7.33329L3.88699 7.99996L5.33366 8.66663L6.00033 10.1133L6.66699 8.66663L8.11366 7.99996L6.66699 7.33329L6.00033 5.88663ZM12.667 5.99996L11.827 4.17329L10.0003 3.33329L11.827 2.49996L12.667 0.666626L13.5003 2.49996L15.3337 3.33329L13.5003 4.17329L12.667 5.99996ZM12.667 15.3333L11.827 13.5066L10.0003 12.6666L11.827 11.8333L12.667 9.99996L13.5003 11.8333L15.3337 12.6666L13.5003 13.5066L12.667 15.3333Z" fill="#2277C9" />
                                                    </svg>
                                                </span>
                                                {isMedicalRecordsGeneratedPreviously ? "Regenerate" : "Generate Medical Summary"}
                                            </button>

                                            <RenderIf shouldRender={isMedicalRecordsGeneratedPreviously}>
                                                <button type='button'
                                                    className="btn btn-theme btn-border"
                                                    onClick={(e) => viewMedicalRecords(e, true)}>
                                                    View Medical Summary
                                                </button>
                                            </RenderIf>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center btm-btns' ref={innerRef}>
                                <a onClick={backToCases} className="text-theme"><i className='fa fa-arrow-left me-2'></i>Back to Case List</a>
                                <div className="btns text-center button-spacing">
                                    <button type='button' className="btn btn-theme btn-border" onClick={() => stepDecrement()}>Previous</button>
                                    <button className="btn btn-theme" type="submit" id="submit">Next</button>
                                </div>
                            </div>
                        </div>}
                    </Form >
                )
                }
            </Formik >

            <Dialog
                isModalOpen={isOpenProcessedMedicalRecordsModal}
                onToggleDialog={(isDialogOpen) => onToggleMedicalRecordsCaseDialog(isDialogOpen)}
                mode="full"
                isCloseIconHidden={false}
            >
                <div className='row'>
                    <div className='col-md-4'>
                        <RenderIf shouldRender={proccessedMedicalRecordsStored?.medicalProcessedData?.length}>
                            <h4>Post-Acident Medical Records</h4>
                            <div style={{ height: '37rem', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', color: 'black' }}>
                                {proccessedMedicalRecordsStored?.medicalProcessedData?.map((data, index) => (
                                    <div key={index}>
                                        <RenderIf shouldRender={data.medicalTypeName === "Hospital"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.treatmentSummary?.map((value, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {value.diagnosis} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Surgery Center Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Procedure Name:" : ""}</b> {values.
                                                        procedures} </p>
                                                ))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "ER"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Admit Date :" : ""}</b> {values.admittedDate} </p>
                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "MRI Other Imaging"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.findings?.map((data, i) => (
                                                        <>
                                                            <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "findings :" : ""}</b> {data} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Consultation Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data?.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Causation :" : ""}</b> {values?.causation} </p>

                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>

                                                        </>
                                                    ))}

                                                    {values?.treatmentSummary?.map((treatmentSummary, index) => (
                                                        <>
                                                            {
                                                                treatmentSummary?.PlanDiscussion?.map((PlanDiscussionData, index) => (
                                                                    <>
                                                                        <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Plan Discussion  :" : ""}</b> {PlanDiscussionData} </p>

                                                                    </>
                                                                ))
                                                            }

                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        {data && data.medicalTypeName === "All Other Medical Records" && (
                                            <RenderIf shouldRender={true}>
                                                <p style={{ marginTop: '2rem', fontSize: '25px' }}>
                                                    <b>Medical Provider Name: </b> {data.name}
                                                </p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}>
                                                    <b>Objective:</b> {data?.objectFindings}
                                                </p>
                                                {data?.diagnosis?.map((diagnosesValue, index) => (
                                                    <>
                                                        {diagnosesValue?.values?.map((diagnosesValues, i) => (
                                                            <React.Fragment key={`${index}-${i}`}>
                                                                <p style={{ fontSize: '20px' }}>
                                                                    <b>{i === 0 ? "Diagnoses:" : ""}</b> {diagnosesValues}
                                                                </p>
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                ))}
                                            </RenderIf>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </RenderIf>

                    </div>
                    <div className='col-md-4'>
                        <RenderIf shouldRender={proccessedMedicalRecordsStored?.preMedicalProcessedData?.length}>
                            <h4>Pre-Acident Medical Records</h4>
                            <div style={{ height: '37rem', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', color: 'black' }}>
                                {proccessedMedicalRecordsStored?.preMedicalProcessedData?.map((data, index) => (
                                    <div key={index}>
                                        <RenderIf shouldRender={data.medicalTypeName === "Hospital"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.treatmentSummary?.map((value, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {value.diagnosis} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Surgery Center Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Procedure Name:" : ""}</b> {values.
                                                        procedures} </p>
                                                ))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "ER"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Admit Date :" : ""}</b> {values.admittedDate} </p>
                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "MRI Other Imaging"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    {values?.findings?.map((data, i) => (
                                                        <>
                                                            <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "findings :" : ""}</b> {data} </p>
                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        <RenderIf shouldRender={data.medicalTypeName === "Consultation Reports"}>
                                            <p style={{ marginTop: '2rem', fontSize: '25px' }}><b>Medical Provider Name: </b> {data?.name}</p>
                                            <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                            {data?.treatmentDates &&
                                                data?.treatmentDates?.map((values, i) => (<>
                                                    <p key={i} style={{ fontSize: '20px' }}><b>{i === 0 ? "Causation :" : ""}</b> {values?.causation} </p>

                                                    {values?.diagnoses?.map((diagnoses, index) => (
                                                        <>
                                                            <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Diagnoses :" : ""}</b> {diagnoses} </p>

                                                        </>
                                                    ))}

                                                    {values?.treatmentSummary?.map((treatmentSummary, index) => (
                                                        <>
                                                            {
                                                                treatmentSummary?.PlanDiscussion?.map((PlanDiscussionData, index) => (
                                                                    <>
                                                                        <p key={index} style={{ fontSize: '20px' }}><b>{index === 0 ? "Plan Discussion  :" : ""}</b> {PlanDiscussionData} </p>

                                                                    </>
                                                                ))
                                                            }

                                                        </>
                                                    ))}
                                                </>))}
                                        </RenderIf>
                                        {data && data.medicalTypeName === "All Other Medical Records" && (
                                            <RenderIf shouldRender={true}>
                                                <p style={{ marginTop: '2rem', fontSize: '25px' }}>
                                                    <b>Medical Provider Name: </b> {data.name}
                                                </p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}><b>Medical Type: </b> {data.medicalTypeName}</p>
                                                <p style={{ marginTop: '1rem', fontSize: '20px' }}>
                                                    <b>Objective:</b> {data?.objectFindings}
                                                </p>
                                                {data?.diagnosis?.map((diagnosesValue, index) => (
                                                    <>
                                                        {diagnosesValue?.values?.map((diagnosesValues, i) => (
                                                            <React.Fragment key={`${index}-${i}`}>
                                                                <p style={{ fontSize: '20px' }}>
                                                                    <b>{i === 0 ? "Diagnoses:" : ""}</b> {diagnosesValues}
                                                                </p>
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                ))}
                                            </RenderIf>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </RenderIf>
                    </div>
                    <div className='col-md-4'>
                        <RenderIf shouldRender={proccessedMedicalRecordsStored?.preMedicalBillProccessedData?.length}>
                            <h4>Medical Bill Records</h4>
                            <div style={{ height: '37rem', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', color: 'black' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#f2f2f2' }}>
                                            <th style={{ padding: '10px', border: '1px solid #ccc' }}>S.No</th>
                                            <th style={{ padding: '10px', border: '1px solid #ccc' }}>Medical Provider Name</th>
                                            <th style={{ padding: '10px', border: '1px solid #ccc' }}>Total Bill Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {proccessedMedicalRecordsStored?.preMedicalBillProccessedData?.map((data, index) => (
                                            <tr key={index}>
                                                <td style={{ padding: '10px', border: '1px solid #ccc', textAlign: 'center' }}>
                                                    {index + 1}
                                                </td>
                                                <td style={{ padding: '10px', border: '1px solid #ccc' }}>
                                                    {data.medicalProviderName}
                                                </td>
                                                <td style={{ padding: '10px', border: '1px solid #ccc', textAlign: 'right' }}>
                                                    {data.totalBillAmount}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </RenderIf>

                    </div>
                </div>
            </Dialog>
            {fileErrorList.length ? <FileUploadErrorModal fileErrorList={fileErrorList} setFileErrorList={setFileErrorList} /> : ""}
        </>
    )


}

export default AddMedicalProviderBillForm;